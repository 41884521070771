import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { ViewList } from '@material-ui/icons';
import {
  ListItem,
  ListItemText,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';

import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import L from 'leaflet';

import {
  useTranslate
} from 'react-admin';
import Modal from '../../components/Modal';
import Control from '../../components/Map/Controls/LeafletControl';

// import moment from 'moment';

const useStyles = makeStyles(theme => ({
  content: { padding: 0 },
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  inline: {
    display: 'inline',
  },
  right: {
    float: 'right',
  }
}));

const OverviewControl = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const map = useMap();

  const [open2, setOpen] = useState(false);
  const [times, setTimes] = useState({});

  const observations = useSelector((state) => state.live_map);

  const selectdEquipment = (id) => {
    map.eachLayer(l => {
      if (l instanceof L.Marker && l.options.id === id) {
        l.openPopup();
        map.setView(l.getLatLng(), 20)
        setOpen(false);
      }
    });
  }

  useEffect(() => {
    const interval_id = setInterval(() => {
      updateTimes();
    }, 1000);

    return () => {
      clearInterval(interval_id);
    }
  }, []);

  useEffect(() => {
    updateTimes();
  }, [observations]);

  const updateTimes = () => {
    const _times = {};
    const now = new Date();

    observations.forEach((equipment) => {
      const serialNumber = equipment.wavy.id;
      const { timestamp } = equipment.wavy.last_transmission;
      const seconds = Math.ceil(Math.abs(now - new Date(timestamp)) / 1000);
      
      const minutes = Math.trunc(seconds / 60);
      const hours = Math.trunc(seconds / 3600);

      
      _times[serialNumber] = {
        seconds,
        final: hours > 0 ? `${Math.round(seconds / 3600)}h` : minutes > 0 ? `${Math.round(seconds / 60)}m` : `${seconds}s`
      }

      // _times[serialNumber].seconds = seconds;
      // _times[serialNumber].final = hours > 0 ? `${hours}h` : minutes > 0 ? `${minutes}m` : `${seconds}s`;
    });

    setTimes({ ...times, ..._times });
  }

  // TODO: translation withou results and title
  return <>
    <Control position="topright" className="leaflet-bar">
      <div>
        <Button
          style={{
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            background: 'white'
          }}
          onClick={() => setOpen(true)}
        >
          <ViewList style={{ fontSize: '15px', padding: '8px', color: 'black' }} />
        </Button>
      </div>
    </Control>
    <Modal
      classes={classes}
      open={open2}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      title={translate('containers.live.overview')}
    >
      <List>
        {observations.length > 0 ? observations.map(({ wavy }) => {
          // const { properties } = position[position.length-1];
          return times[wavy.id] ? <ListItem button onClick={() => selectdEquipment(wavy.id)} key={wavy.id} divider>
            <ListItemText
              primary={wavy.id}
              secondary={
                <>
                  <Typography component="span" className={classes.inline} color="textSecondary">
                    {wavy.last_transmission.bat}
                  </Typography>
                  <Typography component="span" className={classes.right} color="textSecondary">
                    {times[wavy.id].final}
                  </Typography>
                </>
              }
            />
          </ListItem> : null;

        })
          : <Typography>{translate('ra.navigation.no_results')}</Typography>}
      </List>
    </Modal>
  </>
}

export default OverviewControl;