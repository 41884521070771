import { useEffect } from 'react';

import Layout  from './RaLayout/';
import { 
  // Layout, 
  useSetLocale 
} from 'react-admin';

import { useDispatch } from 'react-redux';
// import Menu from './Menu';
import AppBar from './AppBar';
// import Notification from './Notification';
import getTheme from '../../config/theme';
import { loadProfile } from '../../store/actions/user';

const CustomSidebar = props => null;

const CustomLayout = (props) => {
  const setLocale = useSetLocale();
  const dispatch = useDispatch();

  // const theme = useSelector((state) =>
  //   getTheme(state.user.settings.theme)
  // );
  // const dark = () => window.matchMedia("(prefers-color-scheme: dark)").matches
  // const theme = getTheme(dark() ? 'dark' : 'light');
  const theme = getTheme('light');

  // const language = useSelector((state) => state.user.settings.language);
  const language = 'en';

  useEffect(() => {
    setLocale(language);
  }, [setLocale, language]);

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  return (
    <Layout
      {...props}
      sidebar={CustomSidebar}
      appBar={AppBar}
      menu={() => null}
      theme={theme}
    // notification={Notification}
    />
  );
}

export default CustomLayout;