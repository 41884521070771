import { useEffect } from 'react';
import { MapContainer, ScaleControl } from 'react-leaflet';
import moment from 'moment';

import './leaflet-extra-markers/js/leaflet.extra-markers.min.js';
import './leaflet-extra-markers/css/leaflet.extra-markers.min.css';
import L from 'leaflet';

import LayersControl from './LayersControl';
import CenterControl from './Controls/CenterControl';
import LocationControl from './Controls/LocationControl';
import MeasureControl from './Controls/MeasureControl';
import Timeline from './Timeline';

import 'leaflet/dist/leaflet.css';
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
// import RealtimeControl from './RealtimeControl.js';
import RealtimeControlLine from './RealtimeControlLine.js';
// import MarkerCluster from './MarkerCluster.js';

const Map = (props) => {

  const timelineControlOptions = {
    timelineOptions: {

      // marker: (coordinates, properties) => {
      //   console.log("properties", properties)
      //   let marker = L.marker(coordinates, {
      //     icon: L.icon({
      //       iconUrl: my_marker,
      //       shadowUrl: my_marker_shadow,

      //       iconSize:     [30, 44], // size of the icon
      //       shadowSize:   [35, 16], // size of the shadow
      //       iconAnchor:   [15, 41], // point of the icon which will correspond to marker's location
      //       shadowAnchor: [11, 12],  // the same for the shadow
      //       popupAnchor:  [0, -32], // point from which the popup should open relative to the iconAnchor
      //       number: properties.id,
      //     })
      //   });
      //   return marker;
      // },
      // marker: (coordinates, properties) => {
      //   // properties.id = 'WO200';
      //   // console.log("properties", properties)
      //   let marker = L.marker(coordinates, {
      //     icon: L.ExtraMarkers.icon({
      //       icon: properties.id.length > 4 ? 'fb-number' : 'fa-number',
      //       markerColor: 'blue',
      //       prefix: 'fa',
      //       number: properties.id.length > 4 ? `${properties.id.substr(0, 2)}\n${properties.id.substr(2, properties.id.length)}` : properties.id,
      //     })
      //   });
      //   return marker;
      // },

      marker: (coordinates, properties) => {
        // properties.id = 'WO200';
        let marker = L.marker(coordinates, {
          icon: L.ExtraMarkers.icon({
            icon: properties.id.length > 4 ? 'fb-number' : 'fa-number',
            markerColor: 'blue',
            prefix: 'fa',
            number: `${properties.id.substr(0, 2)}\n${properties.id.substr(2, properties.id.length)}`,
          }),
          id: properties.id
        });
        return marker;
      },
      markerPopup: function (properties) {
        let popup = `<h3>${properties.id}</h3>`;
        if (properties.timestamp) {
          popup += `<div>time: ${moment(properties.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')} UTC</div>`
        }
        const hiddenProperties = ['gpstime', 'timestamp', 'position', 'properties', 'serialNumber', 'id', 'key'];
        Object.keys(properties).map((key) => {
          if (!hiddenProperties.includes(key) && properties[key]) {
            return popup += `<div>${key}: ${properties[key]}</div>`;
          }
          return null;
        });

        return popup;
      },
    },
    timecontrolOptions: {
      legend: props.launch ? props.launch.description : '',
      showTicks: false,
      speeds: [1, 2, 4, 8, 16, 32, 64],
      formatOutput: (date) => {
        return `${moment(date).utc().format('DD-MM-YYYY HH:mm:ss')} UTC`;
      }
    }
  };

  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: marker2x,
      iconUrl: marker,
      shadowUrl: markerShadow
    });
  }, []);

  return (
    <MapContainer
      style={{ width: '100%', height: '100vh - 48px' }}
      center={[15, 0]}
      zoom={3}
    >
      <LayersControl>
        {
          (props.timeline && props.geoJSON && <Timeline geoJSON={props.geoJSON} {...timelineControlOptions} />) ||
          (props.realtime && props.realtime.length > 0 && <RealtimeControlLine realtime={props.realtime} controlOptions={timelineControlOptions.timelineOptions} />)
          // (props.realtime && props.realtime.length > 0 && <MarkerCluster markers={props.realtime} controlOptions={timelineControlOptions.timelineOptions}/>)
        }
      </LayersControl>
      <ScaleControl />
      {props.controlCenter && <CenterControl />}
      {props.controlLocation && <LocationControl />}
      {props.controlMeasure && <MeasureControl />}

      {props.children}

    </MapContainer>
  );
};

Map.defaultProps = {
  controlCenter: true,
  controlLocation: false,
  controlMeasure: true,
  timeline: false,
}

export default Map;