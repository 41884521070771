import englishMessages from '../i18n/en';
import portugueseMessages from '../i18n/pt';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
  'en': englishMessages,
  'pt': portugueseMessages,
};

export default polyglotI18nProvider(locale => {
  switch (locale) {
    case 'pt':
      return messages['pt']
  
    default:
      return messages['en']
  }
});