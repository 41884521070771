import L from 'leaflet';

L.Timeline = L.GeoJSON.extend({
  interval: null,
  initialize(geojson, controlLayers, options = {}, setCenter) {

    const defaultOptions = {
      marker: false,
      style: function (feature) {
        return feature.style
      }
    };
    L.GeoJSON.prototype.initialize.call(this, null, options);

    L.Util.setOptions(this, defaultOptions);
    L.Util.setOptions(this, options);

    // this.data = geojson;
    this.data = {
      features: [],
      type: "FeatureCollection"
    };

    this.layersKeys = [];

    let data = JSON.parse(JSON.stringify(geojson));

    this.data.features = data.features.map(feature => {
      feature.geometry.coordinates.reverse();
      return feature;
    });

    this.controlLayers = controlLayers;
    //Get min, max and keys from geojson
    this.interval = this._getInterval();
    this.marker = L.marker;

    this._buildLayers();

    let latlngs = [];

    this.getLayers().forEach(layer => {
      if (layer.feature &&
        layer.feature.type === 'Feature' &&
        layer.feature.geometry &&
        layer.feature.geometry.type === 'GeometryCollection' &&
        layer.feature.geometry.geometries &&
        layer.feature.geometry.geometries.length > 0 &&
        layer.feature.geometry.geometries[0].type === 'LineString' &&
        layer.feature.geometry.geometries[0].coordinates &&
        layer.feature.geometry.geometries[0].coordinates.length > 0
      ) {
        latlngs.push(layer.feature.geometry.geometries[0].coordinates[0]);
        return;
      }
    })

    this.setCenter = setCenter;

    if(latlngs.length > 0) {
      setCenter(latlngs);
    }

  },
  // update(geojson, controlLayers, options = {}) {
  //   console.log("Timeline update")
  //   this.data = {
  //     features: [],
  //     type: "FeatureCollection"
  //   };
  //   let data = JSON.parse(JSON.stringify(geojson));

  //   this.data.features = data.features.map(feature => {
  //     feature.geometry.coordinates.reverse();
  //     return feature;
  //   });

  //   let geojson2 = this.convertPointTrace(this.data, this.interval.min);

  //   geojson2.features = geojson2.features.filter(({ properties }) => !this.layersKeys.includes(properties.key))
  //   if (geojson2.features.length > 0)
  //     this.addData(geojson2)

  //   this.eachLayer(layer => {
  //     if (!this.layersKeys.includes(layer.feature.properties.key)) {
  //       if (!layer.feature.properties.multiLineId) {
  //         this._buildMarker(layer);
  //       }

  //       this.controlLayers.addOverlay(layer, layer.feature.properties.key);
  //       this.layersKeys.push(layer.feature.properties.key);
  //     }
  //   });

  //   this.updateDisplayedLayers(this.currentTime);
  // },
  // get interval from each feature->properties.timestamp
  _getInterval() {
    let times = [];
    const features = this.data.features;
    for (var i in features) {
      if (times.indexOf(features[i].properties.timestamp) < 0)
        times.push(features[i].properties.timestamp);
    }
    times = times.sort();

    return {
      'steps': times,
      'min': times[0],
      'max': times[times.length - 1],
    };
  },
  _buildLayers() {
    let geojson = this.convertPointTrace(this.data, this.interval.min);
    if (geojson) {
      this.addData(geojson)
    }

    this.eachLayer(layer => {
      if (!layer.feature.properties.multiLineId) {
        this._buildMarker(layer);
      }
      this.controlLayers.addOverlay(layer, layer.feature.properties.key);
      this.layersKeys.push(layer.feature.properties.key);
    });
  },
  _buildMarker(layer) {
    let marker;
    let coordinates;
    layer.getLayers().forEach(l => {
      if (l instanceof L.Marker) {
        marker = l;
      } else {
        coordinates = l.getLatLngs();
      }
    });

    if ((this.options.marker !== false) && (coordinates.length > 0)/* && !layer.feature.properties.id.includes("invalid")*/) {
      if (marker) {
        marker.setLatLng(coordinates[coordinates.length - 1]);
        if (this.options.markerPopup)
          marker.setPopupContent(this.options.markerPopup(layer.feature.properties));
      } else {
        if (typeof (this.options.marker) !== "boolean")
          this.marker = this.options.marker;

        let mrk = this.marker(coordinates[coordinates.length - 1], layer.feature.properties);
        if (this.options.markerPopup)
          mrk.bindPopup(this.options.markerPopup(layer.feature.properties));
        layer.addLayer(mrk);
      }
    } else {
      if (marker) {
        layer.removeLayer(marker);
      }
    }
  },
  getIntervalValues() {
    return this.interval;
  },
  // This function must be improved
  updateDisplayedLayers(time) {
    this.currentTime = time;
    let geometries = {};
    let properties = {};
    let geojson = this.convertPointTrace(this.data, time);
    if (geojson) {
      geojson.features.forEach(feature => {
        geometries[feature.properties.id] = feature.geometry.geometries[0];
        properties[feature.properties.id] = feature.properties;
      });
    }

    this.eachLayer(layer => {
      layer.getLayers().forEach(l => {
        if (!(l instanceof L.Marker)) {
          // Clone coordinates
          let geometry = geometries[layer.feature.properties.id].coordinates;
          // Update properties
          layer.feature.properties = properties[layer.feature.properties.id];
          // Update Geometry
          l.setLatLngs(geometry);
          // Rebuild Marker
          if (!layer.feature.properties.multiLineId) {
            this._buildMarker(layer);
          }
        }
      });
    });
  },
  // Must be a GeometryCollection in order to build a LayerGroup, otherwise it will create a layer and will not allow to add the Marker
  convertPointTrace(geojson, time) {
    let elements = {};
    //TODO: CHANGE
    if (geojson.features) {
      geojson.features.forEach(feature => {
        if (!elements[feature.properties.id]) {
          elements[feature.properties.id] = {
            properties: {
              id: feature.properties.id,
              key: feature.properties.key,
            },
            polyline: [],
            multilineCoordinates: {},
          };

          if (feature.style)
            elements[feature.properties.id]['style'] = feature.style;
          if (feature.properties.multiLineId)
            elements[feature.properties.id].properties.multiLine = true;
        }

        if (feature.properties.timestamp <= time) {
          if (feature.properties.multiLineId) {
            if (!elements[feature.properties.id].multilineCoordinates[feature.properties.multiLineId])
              elements[feature.properties.id].multilineCoordinates[feature.properties.multiLineId] = [];

            elements[feature.properties.id].multilineCoordinates[feature.properties.multiLineId].push(feature.geometry.coordinates);
          }
          else {
            elements[feature.properties.id]['polyline'].push(feature.geometry.coordinates);
          }

          elements[feature.properties.id]['properties'] = feature.properties;
        }
      });

      let features = [];
      Object.keys(elements).forEach((i) => {
        let feature = {
          type: "Feature",
          geometry: {
            "type": "GeometryCollection",
            geometries: [] //[{ type: "LineString", coordinates: elements[i].polyline }]
          },
          properties: elements[i].properties
        };


        // if(Object.keys(elements[i].multiline).length > 0) {
        if (feature.properties.multiLineId) {
          let coordinates = [];
          feature.properties.multiLineIds = Object.keys(elements[i].multilineCoordinates);
          Object.keys(elements[i].multilineCoordinates).forEach(identifier => coordinates.push(elements[i].multilineCoordinates[identifier]));
          feature.geometry.geometries.push({
            type: "MultiLineString", coordinates: coordinates
          });
        }
        else {
          feature.geometry.geometries.push({ type: "LineString", coordinates: elements[i].polyline });
        }

        if (elements[i])
          feature['style'] = elements[i].style;

        features.push(feature);
      });

      return {
        type: "FeatureCollection",
        features: features
      };
    } else {
      return null;
    }
  },
  onRemove(map) {
    this.eachLayer(layer => {
      map.removeLayer(layer);
      this.controlLayers.removeLayer(layer);
    })
  }
});

export default L.timeline = (geojson, controlLayers, options, setCenter) => new L.Timeline(geojson, controlLayers, options, setCenter);
