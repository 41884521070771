import ReactDOM from 'react-dom';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import {useEffect} from 'react';

const CustomControl = (props) => {
  const context = useLeafletContext();

  L.Control.Button = L.Control.extend({
    onAdd: function (map) {
      const container = L.DomUtil.create('div', this.options.className);
      L.DomEvent.disableClickPropagation(container);
      ReactDOM.render(this.options.children, container);
      return container;
    },
    onRemove: function (map) {}
  });

  L.control.button = function (opts) {
    return new L.Control.Button(opts);
  }

  useEffect(() => {
    const container = context.layerContainer || context.map

    const control = L.control.button({...props});
    container.addControl(control)

    return () => {
      container.removeControl(control)
    }
  });

  return null;
};

export default CustomControl;