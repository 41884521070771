import { defaultTheme } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';

const getTheme = (type) => createTheme({
  ...defaultTheme,
  palette: {
    type: type,
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    }
  }
});

export default getTheme;