import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  paper: {
    overflowY: 'hidden',
  }
}));

const SimpleModal = ({ children, footer, maxWidth, onClose, onEntering, open, title, scroll }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      paper={classes.paper}
      onClose={() => onClose()}
      onEntering={onEntering}
      fullWidth
      scroll={scroll || 'paper'}
      maxWidth={maxWidth || false}
      open={open}
    >
      <div className={classes.titleDiv}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
      {footer}
    </Dialog>
  );
};

export default SimpleModal;