import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        link: {
            textDecoration: 'none',
            color: theme.palette.primary.main,
        },
    }),
    { name: 'CLink' }
);

const Link = props => {
    const {
        to,
        children,
        classes: classesOverride,
        className,
        ...rest
    } = props;
    const classes = useStyles(props);
    return (
        <a
            href={to}
            className={classNames(classes.link, className)}
            {...rest}
        >
            {children}
        </a>
    );
};

Link.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string,
};

export default Link;