import { Children, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import { ComponentPropType } from 'ra-core';

import { HideOnScroll, LoadingIndicator, Link } from 'react-admin';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.2em',
      marginRight: '0.2em',
    },
    link: {
      color: "inherit"
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' }
);

const AppBar = (props) => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logout,
    open,
    title,
    userMenu,
    container: Container,
    ...rest
  } = props;
  const classes = useStyles(props);
  const isXSmall = useMediaQuery(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <Container>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <Link to={{
            pathname: `/`,
          }}
            className={classNames(classes.link)}
          >
            <Tooltip
              title='Home'
              enterDelay={500}
            >
              <IconButton
                className={classNames(classes.menuButton)}
                color="inherit"
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </Link>
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <LoadingIndicator />
        </Toolbar>
      </MuiAppBar>
    </Container>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  // @ts-ignore
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  container: ComponentPropType,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

AppBar.defaultProps = {
  container: HideOnScroll,
};


export default memo(AppBar);