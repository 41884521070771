export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export const changeSettings = ({theme, language}) => {
  return ({
    type: CHANGE_SETTINGS,
    theme,
    language
  });
};

export const loadSettings = () => {
  return ({
    type: LOAD_SETTINGS
  });
};

export const saveSettings = () => {
  return ({
    type: UPDATE_SETTINGS
  });
};

export const saveSettingsSuccess = ({theme, language}) => {
  return ({
    type: UPDATE_SETTINGS_SUCCESS,
    theme,
    language
  });
};

export const saveSettingsFailure = error => {
  return ({
    type: UPDATE_SETTINGS_FAILURE,
    error
  });
};


export const CHANGE_PROFILE = 'CHANGE_PROFILE';
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const loadProfile = () => {
  return ({
    type: LOAD_PROFILE
  });
};

export const saveProfile = ({id, name, username, email}) => {
  return ({
    type: UPDATE_PROFILE,
    id,
    username, 
    name, 
    email
  });
};

export const saveProfileSuccess = ({id, name, username, email}) => {
  return ({
    type: UPDATE_PROFILE_SUCCESS,
    id,
    username, 
    name, 
    email
  });
};

export const saveProfileFailure = error => {
  return ({
    type: UPDATE_PROFILE_FAILURE,
    error
  });
};