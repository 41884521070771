import { useEffect, useState } from 'react';
import {
  Title,
  // useTranslate,
  fetchStart,
  fetchEnd,
  showNotification
} from 'react-admin';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LeafletMap from '../../components/Map/Map';
import dataFetch from '../../providers/dataFetch';
import { fetchObservations } from '../../store/actions/historyMap';

const Map = (props) => {
  // const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [timeline, setTimeline] = useState(false);
  const [launch, setLaunch] = useState(false);

  const observations = useSelector((state) => state.history_map.observations);

  useEffect(() => {
    dispatch(fetchStart());
    dataFetch('GET', `/launches/${id}`)
      .then(data => {
        setLaunch(data);
        return dataFetch('GET', `/observations/launch/${id}`)
      })

      .then(data => {
        dispatch(fetchObservations(data));
        data.length === 0 ?
          dispatch(showNotification('containers.history.no_data')) :
          setTimeline(true);
      })
      .catch(err => {
        // if (err.code === 'MODEL_NOT_FOUND') {
        //   history.replace('/');
        // }
        // else {
          dispatch(showNotification('ra.page.error', 'warning'));
          setTimeout(() => history.replace('/'), 1500);
        // }
      })
      .finally(_ => dispatch(fetchEnd()));
  }, []);


  return (
    <div style={{ display: 'flex', flex: '1' }}>
      <Title title={(launch && launch.name) || ' '/* 'Wrong launch'*/} />
      <LeafletMap controlLocation={true} controlMeasure={true} timeline={timeline} launch={launch} geoJSON={observations} />
    </div>
  );
};

export default Map;