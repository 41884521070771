export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';

export const updatePage = ({ page }) => {
  return ({
    type: UPDATE_PAGE,
    payload: page
  });
};

export const loadPage = ({ campaignId }) => {
  return ({
    type: UPDATE_CAMPAIGN,
    payload: campaignId
  });
};
