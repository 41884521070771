import {
  FETCH_OBSERVATIONS,
  CLEAR_OBSERVATIONS,
  STORE_OVERLAYS,
  SELECT_CAMPAIGN,
} from '../actions/historyMap';

const initialState = {
  observations: undefined,
  overlays: undefined,
  activeCampaign: undefined,
};

export default (state = initialState, action) => {

  switch (action.type) {

    case FETCH_OBSERVATIONS:
      return { ...state, observations: action.payload };
    
    case CLEAR_OBSERVATIONS:
      return { ...state, observations: undefined };

    case STORE_OVERLAYS:
      return { ...state, overlays: action.payload };

    case SELECT_CAMPAIGN:
      return { ...state, activeCampaign: action.payload };

    default:
      return state;
  }
};
