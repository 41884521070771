import Button from '@material-ui/core/Button';
import CenterIcon from '@material-ui/icons/FilterCenterFocus';
import { makeStyles } from '@material-ui/core/styles';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import Control from './LeafletControl';

const useStyles = makeStyles(theme => ({
  div: {
    background:'white',
    borderRadius: '4px'
  },
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width: '34px',
    height: '34px',
    border: '2px solid rgba(0,0,0,0.4)',
    borderRadius: '4px'
  },
  icon: {
    fontSize: '18px',
    padding: '8px',
    color: 'black'
  }
}));

const CenterControl = (props) => {
  const classes = useStyles();
  const context = useLeafletContext();

  const centerMapHandler = () => {
    const { map } = context;
    let latlngs = [];

    map.eachLayer(layer => {
      if ((layer instanceof L.Polyline) && (layer.getLatLngs().length > 0)) {
        latlngs = [...latlngs, ...layer.getLatLngs()];
      }
    });

    if (latlngs.length > 0) {
      map.fitBounds(latlngs);
    }
  };

  return (
    <Control position='topleft'>
      <div className={classes.div}>
        <Button 
          disableRipple
          {...props}
          className={classes.button}
          onClick={centerMapHandler}
        >
          <CenterIcon className={classes.icon}/>
        </Button>
      </div>
    </Control>
  );
};

export default CenterControl;