import { toWavyGeojson, toWavyGeojsonOld } from '../../factory'; 

export const LIVE_OBSERVATIONS = 'LIVE_OBSERVATIONS';
export const FETCH_OLD_OBSERVATIONS = 'FETCH_OLD_OBSERVATIONS';
export const CLEAR_OBSERVATIONS = 'CLEAR_OBSERVATIONS';

export const liveObservations = (data) => {
  return ({
    type: LIVE_OBSERVATIONS,
    payload: toWavyGeojson(data)
  });
};

export const fetchOldObservations = (data) => {
  return ({
    type: FETCH_OLD_OBSERVATIONS,
    payload: toWavyGeojsonOld(data)
  });
};

export const clearObservations = () => {
  return ({
    type: CLEAR_OBSERVATIONS
  });
}
